import { ProgramDay } from "./programDay";

type ProgramProps = {
  program?: { _key: string; [key: string]: any }[];
};

export const Program = ({ program }: ProgramProps) => {
  return (
    <section className="c-program">
      <div className="o-wrapper">
        <h2 className="u-hide--desktop u-padding-bottom--small">Program</h2>
        {program && (
          <div className="c-event-info__main--margin-bottom">
            <div className="">
              {program.map((programDay, index) => {
                return (
                  <ProgramDay
                    key={programDay._key}
                    programDay={programDay}
                    startOpen={index === 0}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
