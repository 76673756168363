import React from "react";
import GoogleMapReact from "google-map-react";
import PortableText from "components/portableText/portableText";

type LocationProps = {
  location: {
    title?: string;
    locationPoint?: {
      lat: number;
      lng: number;
    };
    portableText?: any;
  };
};

const PinWrapper = (lat, lng) => (
  // eslint-disable-next-line @next/next/no-img-element
  <img src="/pin.png" className={"c-location__image"} alt=""></img>
);

export const Location = ({ location }: LocationProps) => {
  const { title, locationPoint, portableText } = location;
  const { lat, lng } = locationPoint || {};

  // center the map on the placed pin
  const center = {
    lat: lat || 60.34762,
    lng: lng || 5.35053,
  };

  // NOTE: Key is restricted to selected API by referrer web site
  return (
    <div className="c-location">
      <div className="o-wrapper">
        {title && <h3 className="c-location__title">{title}</h3>}

        {portableText && <PortableText blocks={portableText} />}

        {locationPoint && (
          <div className="c-location__map">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyAojzaxVgnnXkLkhmfm06Pek338GigzPMI",
              }}
              defaultCenter={center}
              defaultZoom={15}
            >
              <PinWrapper lat={lat} lng={lng} />
            </GoogleMapReact>
          </div>
        )}
      </div>
    </div>
  );
};
